import redirect from 'helpers/redirect'
import Coin from 'assets/svg/coin.svg'
import { getStateFromSource, trackEvent } from 'helpers/analytics'
import CreatorCaption from 'components/commons/CreatorCaption'
import isEmpty from 'helpers/is-empty'
import handleErrorImage from 'helpers/error-image'
import Button from 'components/commons/Button'
import { useState } from 'react'
import {
  SessionLiveContainer,
  StyledCardMediaLive,
  StyledLiveInfo,
  StyledLiveStatus,
  StyledLiveText,
  StyledLiveType,
  StyledSessionNameText,
  StyledTextLiveStatus,
} from './styled'
import {
  LayoutCard,
  StyledCard,
  StyledCoverImage,
  StyledImage,
} from 'components/commons/Card/VideoCard/v2/styled'
import { useTranslation } from 'react-i18next'

interface ISessionStatus {
  status?: string
  type?: string
  cardType?: string
  name?: string
  title?: string
  url?: string
  isVerified?: boolean
}

const SessionStatusLive = ({
  type,
  cardType,
  name,
  title,
  url,
  isVerified = false,
}: ISessionStatus): JSX.Element => {
  const { t } = useTranslation()
  const [isHovering, setIsHovering] = useState(false)

  return (
    <SessionLiveContainer
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => setIsHovering(false)}
    >
      <StyledLiveStatus>
        <StyledTextLiveStatus>• Live</StyledTextLiveStatus>
      </StyledLiveStatus>
      <StyledLiveInfo>
        {type && type === 'premium' ? (
          <StyledLiveType>
            {cardType == 'vertical' && (
              <>
                <StyledLiveText>{type}</StyledLiveText>
                <Coin />
              </>
            )}
          </StyledLiveType>
        ) : (
          <StyledLiveType>
            <StyledLiveText>{type}</StyledLiveText>
          </StyledLiveType>
        )}
        <StyledSessionNameText marginTop="4px">
          {title}
        </StyledSessionNameText>
        <CreatorCaption
          title={name}
          isVerified={isVerified}
          size="sm"
          isRegular
          sx={{ color: 'white' }}
        />
        {isHovering && (
          <Button
            sx={{ marginTop: { xs: '8px', md: '4px' }, zIndex: 5 }}
            href={url}
          >
            {t('key_join_session')}
          </Button>
        )}
      </StyledLiveInfo>
    </SessionLiveContainer>
  )
}

interface IVideoCard {
  id?: string
  title?: string
  video?: string
  name?: string
  views?: string | number
  date?: string
  url?: string
  isLive?: boolean
  status?: string
  type?: string
  pathName?: string
  groupName?: string
  creatorId?: string
  creatorUserId?: string
  creatorGroup?: string
  creatorIsCommunityLeader?: boolean | string
  categoryId?: string
  categoryName?: string
  cardType?: string
  className?: string
  isVerified?: boolean
  sectionId?: string
  sectionTitle?: string
  sectionContentType?: string
}

function VideoCard({
  id = '',
  title = '',
  video = '',
  name = '',
  url,
  status = '',
  type = '',
  cardType = 'vertical',
  pathName = '',
  groupName = '',
  creatorId = 'unknown',
  creatorUserId = 'unknown',
  creatorGroup = '',
  creatorIsCommunityLeader = '',
  categoryId = 'unknown',
  categoryName = 'unknown',
  isVerified = false,
  className = '',
  sectionId = '',
  sectionTitle = '',
  sectionContentType = '',
}: IVideoCard) {
  const redirectPage = (_url) => {
    if (pathName !== '') {
      trackEvent('select_session', {
        state_name: getStateFromSource(pathName),
        state_sub_name: groupName,
        creator_id: creatorId,
        creator_user_id: creatorUserId,
        creator_category_id: categoryId,
        creator_category: categoryName,
        creator_group: creatorGroup,
        is_community_leader: creatorIsCommunityLeader,
        session_id: id,
        session_title: title,
        creator_name: name,
        selected_browse_and_search_menu: !isEmpty(sectionId)
          ? undefined
          : 'session_tab',
        section_id: !isEmpty(sectionId) ? sectionId : undefined,
        section_title: !isEmpty(sectionTitle)
          ? sectionTitle
          : undefined,
        section_content_type: !isEmpty(sectionContentType)
          ? sectionContentType
          : undefined,
      })
    }
    redirect(_url)
  }
  return (
    <LayoutCard
      className={className}
      status={status}
      onClick={() => redirectPage(url)}
    >
      <StyledCard type={cardType}>
        <StyledImage type={cardType}>
          <StyledCardMediaLive title={'title'}>
            <StyledCoverImage
              src={video}
              onError={({ currentTarget }) => {
                handleErrorImage({ currentTarget, type: 'default' })
              }}
              alt="cover-live-session"
            />
          </StyledCardMediaLive>
          {status && status !== '' && (
            <SessionStatusLive
              type={type}
              status={status}
              cardType={cardType}
              name={name}
              title={title}
              url={url}
              isVerified={isVerified}
            />
          )}
        </StyledImage>
      </StyledCard>
    </LayoutCard>
  )
}

export default VideoCard
