import * as React from 'react'
import Card from '@mui/material/Card'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'

const LayoutCard = styled('div')({
  minWidth: '187px',
  minHeight: '168px',
})

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',

  background: theme.palette.background.primary,
}))

const StyledSkeleton = styled(Skeleton)({
  borderRadius: '8px',
  backgroundColor: 'lightgrey',
})

function VideoCardSkeleton() {
  return (
    <LayoutCard>
      <StyledCard>
        <StyledSkeleton
          variant="rectangular"
          width={187}
          height={168}
        />
      </StyledCard>
    </LayoutCard>
  )
}

export default VideoCardSkeleton
