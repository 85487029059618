import { styled } from '@mui/material/styles'
import { Link } from '@mui/material'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

type StyledCardProps = { isEvent: boolean }

type StyledPurchasedBadgeProps = { badgeColor: string }

export const StyledCard = styled(Stack)<StyledCardProps>(
  ({ theme, isEvent }) => ({
    background: theme.palette.background.primary,
    width: '163px',
    height: isEvent ? 'auto' : '306px',
    position: 'relative',
    paddingLeft: 0,
    cursor: 'pointer',
    borderRadius: '12px',
    marginRight: '12px',
    [theme.breakpoints.up('md')]: {
      marginRight: '16px',
      height: isEvent ? 'auto' : '356px',
      width: 'auto',
      maxWidth: '211px',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '0px',
      width: 'auto',
    },
    boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
  }),
)

export const StyledImage = styled(Box)({
  height: '163px',
  img: {
    borderRadius: '12px',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  },
  '@media only screen and (min-width: 900px)': {
    height: '211px',
    img: {
      height: '211px',
    },
  },
})

export const StyledBoxType = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: theme.palette.background.tertiery,
  borderRadius: '4px',
  height: '20px',
  padding: '2px 4px',
  color: theme.palette.text.secondary,
  width: 'max-content',
  ...theme.typography.smallRegular,

  '@media only screen and (min-width: 900px)': {
    height: '22px',
    ...theme.typography.normalRegular,
  },
}))

export const StyledTitle = styled(Link)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minHeight: '34px',
  wordBreak: 'normal',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.mediumBold,
  },
  ':hover': {
    color: theme.palette.tiptip[500],
  },
  zIndex: 5,
  textDecoration: 'none',
}))

export const StyledPrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  marginRight: '4px',
  '@media only screen and (min-width: 900px)': {
    ...theme.typography.h3,
  },
}))

export const StyledSalePrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  color: theme.palette.text.secondary,
  marginRight: '4px',
  textDecorationLine: 'line-through',
  '@media only screen and (min-width: 900px)': {
    ...theme.typography.normalRegular,
  },
}))

export const StyledPriceContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '12px',
})

export const StyledPurchasedBadge = styled(
  Stack,
)<StyledPurchasedBadgeProps>(({ theme, badgeColor }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '12px',
  fontSize: '12px',
  color: theme.palette.text.primary,
  svg: {
    color: badgeColor,
    height: '16px',
    width: '16px',
    marginRight: '6px',
  },
}))
