import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import apiClient from 'helpers/api-client'
import isEmpty from 'helpers/is-empty'
import { useRouter } from 'next/router'
import redirect from 'helpers/redirect'
import imageResizer from 'helpers/image-resizer'
import { getSectionDetail } from 'services/campaign'
import trackEvent from 'src/trackers'
import { getIdToken } from 'helpers/auth'
import { isGuestMode } from 'helpers/cookie'

import SessionsSliderSkeleton from './Skeleton'
import SectionContainer from '../SectionContainer/v2'

import VideoLiveCard from 'containers/commons/VideoLiveCard'

import MobileContentSlider from 'components/domains/Home/MobileContentSlider'
import DesktopContentSlider from 'components/domains/Home/DesktopContentSlider'
import Grid from 'components/commons/Grid'
import VideoCard from 'components/commons/Card/VideoCard/v2'
import SeeAllCard from 'components/commons/Card/SeeAllCard'
import Stack from 'components/commons/Stack'
import { SxProps } from '@mui/material'

const StyledVideoCard = styled(VideoCard)({
  overflow: 'hidden',
  position: 'relative',
})

const StyledVideoLiveCard = styled(VideoLiveCard)({
  overflow: 'hidden',
})

interface ISessionsSlider {
  title?: string
  path?: string
  token?: string
  groupName?: string
  pathName?: string
  pathSeeAll?: string
  showViewAll?: boolean
  numOfCol?: number
  isThematicCampaign?: boolean
  sectionId?: string
  sectionType?: string
  sxContainer?: SxProps
}

function SessionsSlider({
  title,
  path,
  token = '',
  groupName = '',
  pathName = '',
  pathSeeAll = '',
  showViewAll = false,
  numOfCol = 6,
  isThematicCampaign = false,
  sectionId,
  sectionType,
  sxContainer,
}: ISessionsSlider) {
  const { pathname, query } = useRouter()
  const trackerData = { pathname, query }
  const [sessions, setSessions] = useState({
    data: [],
    _metadata: {
      load_more: false,
    },
  })

  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const getSessions = async () => {
    setIsLoading(true)
    try {
      let url = ''
      if (isThematicCampaign) {
        const url = `/campaign${
          isGuestMode() ? '/guest' : ''
        }/v1/section/${sectionId}?`
        const response = await getSectionDetail(url, {
          page: 1,
          limit: 20,
        })
        const sessionList = response?.data?.data?.session_list || []
        setSessions({
          data: sessionList,
          _metadata: {
            load_more: sessionList.length >= 5,
          },
        })
      } else {
        if (!isGuestMode()) {
          url = '/session/' + path
        } else {
          url = '/session/guest/' + path
        }

        const response = await apiClient({
          url: url,
          method: 'GET',
          params: {
            page: 1,
            limit: 10,
          },
          token: isGuestMode() ? null : getIdToken(),
          cache:
            path === 'v1/upcoming'
              ? {
                  interpretHeader: true,
                  cacheTakeover: false,
                }
              : false,
        })
        setSessions(response.data)
      }
      setIsLoading(false)
      setIsSuccess(true)
    } catch (error) {
      setIsLoading(false)
      setIsSuccess(false)
    }
  }

  const getSessionRecomendation = async (page = 1, prevData = []) => {
    setIsLoading(true)
    try {
      let url = `/recommendation/guest/v1/session${path}`
      if (groupName === 'session_you_might_like' && isGuestMode()) {
        url = `/session/guest/v1/search${path}`
      }
      if (!isGuestMode()) {
        url = `/recommendation/v1/session${path}?source=recommendation`
      }
      const response = await apiClient({
        url: url,
        method: 'GET',
        params: {
          page,
          limit: 10,
        },
        token: isGuestMode() ? null : getIdToken(),
        cache: !isEmpty(token)
          ? {
              interpretHeader: true,
              cacheTakeover: false,
            }
          : false,
      })
      const data = [...prevData, ...response.data.data]
      setSessions({
        data,
        _metadata: { ...response.data._metadata },
      })
      if (
        !isEmpty(token) &&
        data.length < 10 &&
        response.data._metadata.load_more
      ) {
        getSessionRecomendation(page + 1, response.data.data)
      }
      setIsLoading(false)
      setIsSuccess(true)
    } catch (error) {
      setIsLoading(false)
      setIsSuccess(false)
    }
  }

  const handleClickShowMore = () => {
    if (isThematicCampaign) {
      trackEvent.home('click_see_section_detail', trackerData, {
        section_id: sectionId,
        section_title: title,
        section_content_type: sectionType,
      })
    }
    redirect(pathSeeAll)
  }

  const renderSessionItem = (session) => {
    let sourceAction = ''
    let cardType = ''
    if (path === 'v1/upcoming') {
      sourceAction = 'trigger_session_upcoming'
      cardType = 'upcoming'
    } else if (path === 'v1/live') {
      sourceAction = 'trigger_session_live_now'
      cardType = 'live'
    } else if (path === 'v1/live/interest') {
      sourceAction = 'trigger_session_you_might_like'
      cardType = 'interest'
    } else {
      sourceAction = 'trigger_recommendation_section'
      cardType = 'recommendation'
    }
    return (
      <div key={'session-card-' + session.id}>
        {session.status === 'live' &&
        cardType !== 'recommendation' ? (
          <StyledVideoLiveCard
            className={`c-home-video-${
              isThematicCampaign ? 'thematic' : cardType
            }-card`}
            id={session.id}
            title={session.name}
            video={imageResizer(session.creator.avatar, '300w')}
            name={session.creator_name}
            date={session.date_time}
            views={session.views_number}
            url={`/session${
              session.session_type == 'free' ? '/live' : ''
            }/${
              session.id
            }?source=${pathname}&source_action=${sourceAction}`}
            isLive={session.is_live}
            status={session.status}
            type={session.session_type}
            pathName={pathName}
            groupName={groupName}
            creatorId={session?.creator?.creator_id}
            creatorUserId={session?.creator?.creator_user_id}
            creatorGroup={session?.creator?.creator_group}
            creatorIsCommunityLeader={
              session?.creator?.is_community_leader
            }
            categoryId={String(session?.creator?.category?.id)}
            categoryName={session?.creator?.category?.name}
            isVerified={session?.creator?.verified}
            sectionId={sectionId}
            sectionTitle={title}
            sectionContentType={sectionType}
          />
        ) : (
          <StyledVideoCard
            className={`c-home-video-${
              isThematicCampaign ? 'thematic' : cardType
            }-card`}
            id={session.id}
            title={session.name}
            video={imageResizer(session.background_img, '300w')}
            name={session.creator_name}
            date={session.date_time}
            views={session.views_number}
            url={`/session/${session.id}?source=${pathname}&source_action=${sourceAction}`}
            isLive={session.is_live}
            status={session.status}
            type={session.session_type}
            pathName={pathName}
            groupName={groupName}
            creatorId={session?.creator?.creator_id}
            creatorUserId={session?.creator?.creator_user_id}
            creatorGroup={session?.creator?.creator_group}
            creatorIsCommunityLeader={
              session?.creator?.is_community_leader
            }
            categoryId={String(session?.creator?.category?.id)}
            categoryName={session?.creator?.category?.name}
            isVerified={session?.creator?.verified}
            sectionId={sectionId}
            sectionTitle={title}
            sectionContentType={sectionType}
          />
        )}
      </div>
    )
  }

  const renderSessions = () => {
    const isShowSeeAllCard = sessions.data.length > 6
    let newDataArray = isShowSeeAllCard
      ? [...sessions.data, {}]
      : sessions.data

    return newDataArray?.map((item, index) => {
      if (isShowSeeAllCard && index === newDataArray.length - 1) {
        return (
          <SeeAllCard
            key={index}
            name={title}
            url={pathSeeAll}
            sxProps={{
              width: '168px',
              height: 'auto',
            }}
          />
        )
      } else {
        return renderSessionItem(item)
      }
    })
  }

  const renderItemCarousel = () => {
    let sessionItem = []
    let sessionContainer = []
    const isShowSeeAllCard = sessions.data.length > 6

    let newDataArray = isShowSeeAllCard
      ? [...sessions.data, {}]
      : sessions.data

    if (sessions?.data?.length) {
      for (let index = 0; index < newDataArray.length; index++) {
        const session = newDataArray[index]
        const element = (
          <Grid
            item
            md={numOfCol === 4 ? 3 : 2}
            lg={numOfCol === 4 ? 3 : 2}
            key={session.id}
          >
            {isShowSeeAllCard && index === newDataArray.length - 1 ? (
              <SeeAllCard name={title} url={pathSeeAll} />
            ) : (
              renderSessionItem(session)
            )}
          </Grid>
        )

        sessionItem.push(element)
        if (
          sessionItem.length === numOfCol ||
          index === newDataArray.length - 1
        ) {
          const container = (
            <Grid
              key={`container-${index}`}
              container
              columnSpacing={{ xs: '12px', md: 2 }}
            >
              {sessionItem.map((item) => item)}
            </Grid>
          )
          sessionContainer.push(container)
          sessionItem = []
        }
      }

      return sessionContainer.map((item) => item)
    }
  }

  useEffect(() => {
    if (
      groupName === 'session_you_might_like' ||
      groupName === 'recommended_session' ||
      groupName === 'session_upcoming'
    ) {
      getSessionRecomendation()
    } else {
      getSessions()
    }
  }, [])

  if (isLoading) {
    return <SessionsSliderSkeleton />
  }

  if (!isLoading && isSuccess && sessions?.data?.length > 0) {
    return (
      <SectionContainer
        sectionName={title}
        showViewAll={
          isThematicCampaign
            ? sessions?._metadata?.load_more
            : showViewAll
        }
        onClickViewAll={handleClickShowMore}
        sxContainer={sxContainer}
      >
        <DesktopContentSlider>
          {renderItemCarousel()}
        </DesktopContentSlider>
        <MobileContentSlider numOfRow={1}>
          <Stack
            direction={'row'}
            gap={'8px'}
            sx={{
              padding: '4px',
            }}
          >
            {renderSessions()}
          </Stack>
        </MobileContentSlider>
      </SectionContainer>
    )
  }

  return null
}

export default SessionsSlider
