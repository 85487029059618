import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SessionStatusContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  width: '100%',
  height: 20,
  bottom: 0,
  borderBottomLeftRadius: 8,
  paddingLeft: 6,
  borderBottomRightRadius: 8,
})

export const StyledBullet = styled(Box)({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  marginRight: 6,
})

export const StyledTextStatus = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 11,
})

export const StyledType = styled(Box)({
  borderBottomRightRadius: 8,
  height: '100%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '3px',
})

export const StyleTypeText = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallBold,
  color: 'white',
  textTransform: 'capitalize',
}))

export const StyledStatus = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const StyledIconImage = styled('img')({
  width: '16px',
  height: '16px',
  objectFit: 'cover',
})
