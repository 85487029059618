import Stack from 'components/commons/Stack'
import { styled } from '@mui/material/styles'
import VideoCardSkeleton from 'components/commons/Card/VideoCard/Skeleton'

const StyledVideoCardSkeleton = styled(VideoCardSkeleton)({
  overflowX: 'scroll',
})

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  padding: '0px 128px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px',
  },
}))

function SessionsSliderSkeleton() {
  return (
    <StyledStack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      spacing={1}
    >
      {Array.from(Array(6)).map((_, i) => (
        <StyledVideoCardSkeleton key={i} />
      ))}
    </StyledStack>
  )
}

export default SessionsSliderSkeleton
