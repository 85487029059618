import { CardMedia, styled } from '@mui/material'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

export const StyledCardMediaLive = styled(CardMedia)(({ theme }) => ({
  width: '187px',
  height: '167px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: '100%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}))
export const SessionLiveContainer = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  padding: '8px 12px',
  display: 'flex',
  flexDirection: 'column',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
  borderRadius: '7px',
})

export const StyledLiveStatus = styled(Box)(({ theme }) => ({
  background: theme.colors.gradient1,
  padding: '3px 4px',
  width: 'fit-content',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '43px',
  },
}))

export const StyledTextLiveStatus = styled(Typography)(
  ({ theme }) => ({
    fontSize: 12,
    fontWeight: 700,
    color: 'white',
    [theme.breakpoints.up('md')]: {
      ...theme.typography.normalBold,
    },
  }),
)

export const StyledLiveInfo = styled(Box)({
  marginTop: 'auto',
})

export const StyledLiveType = styled(Box)(({ theme }) => ({
  background: theme.palette.info.main,
  padding: '3px 4px',
  borderRadius: '4px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledLiveText = styled(Typography)(({ theme }) => ({
  color: 'white',
  ...theme.typography.smallBold,
  textTransform: 'capitalize',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordWrap: 'break-word',
  display: '-webkit-box',

  [theme.breakpoints.up('md')]: {
    ...theme.typography.normalBold,
  },
}))

export const StyledSessionNameText = styled(StyledLiveText)(
  ({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      ...theme.typography.body1,
      fontWeight: 700,
      lineHeight: '22px',
    },
  }),
)
