import { CardContent, CardMedia } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from 'components/commons/Box'
import { StyledTitle } from 'containers/domains/Home/styled'
import Card from '../..'

type CardTypeProps = {
  type?: string
}

type StatusProps = {
  status?: string
}

export const LayoutCard = styled(Box)<StatusProps>(
  ({ status = '', theme }) => ({
    width: status === 'live' ? '163px' : '100%',
    cursor: 'pointer',
    position: 'relative',
    '& .css-nznqlf-MuiCardContent-root:last-child': {
      paddingBottom: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      width: status === 'live' ? 'auto' : '100%',
      height: status === 'live' ? 'auto' : '100%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '287px',
    },
    boxShadow: `0px 0px 8px 0px ${theme.palette.border.bold}`,
    borderRadius: '12px',
    [theme.breakpoints.down('md')]: {
      width: status === 'live' ? '168px' : '288px',
      height: status === 'live' ? 'auto' : '100%',
    },
  }),
)
export const StyledCard = styled(Card)<CardTypeProps>(
  ({ type = '', theme }) => ({
    boxShadow: 'none',
    background: theme.palette.background.primary,
    display: type === 'horizontal' ? 'flex' : 'unset',
    justifyItems: type === 'horizontal' ? 'flex-start' : 'unset',
    position: 'relative',
    width: '100%',
    height: '100%',
  }),
)

export const StyledCardMedia = styled(CardMedia)<CardTypeProps>(
  ({ type = '' }) => ({
    paddingTop: '56.25%',
    borderRadius: '8px',
    width: type === 'horizontal' ? 124 : 'none',
    height: type === 'horizontal' ? 72 : 0,
  }),
)

export const StyledCardContent = styled(CardContent)<CardTypeProps>(
  ({ type = '' }) => ({
    padding:
      type === 'horizontal'
        ? '0px 8px !important'
        : '12px 12px !important',
  }),
)

export const StyledSessionTitle = styled(StyledTitle)(
  ({ theme }) => ({
    ...theme.typography.mediumBold,
    minHeight: '34px',
    marginBottom: '8px',
    ':hover': {
      color: theme.palette.text.primary,
    },
  }),
)

export const Bullet = styled('span')(({ theme }) => ({
  display: 'inline-block',
  margin: '0 8px',
  transform: 'scale(2.0)',
  color: theme.palette.text.secondary,
}))

export const StyledImage = styled(Box)<CardTypeProps>(
  ({ type = '' }) => ({
    position: 'relative',
    height: type === 'horizontal' ? 72 : '172px',
  }),
)

export const StyledCoverImage = styled('img')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '8px',
})
