import { ISessionStatus } from '../interface'
import {
  SessionStatusContainer,
  StyledBullet,
  StyledIconImage,
  StyledStatus,
  StyledTextStatus,
  StyledType,
  StyleTypeText,
} from './styled'
import appConfig from 'configs/app'
import { useTheme } from '@mui/material'

const SessionStatus = ({
  status,
  type,
  cardType,
}: ISessionStatus): JSX.Element => {
  const theme = useTheme()
  let bgColor = ''
  let bulletColor = ''
  let textColor = ''
  switch (status.toLocaleLowerCase()) {
    case 'upcoming':
      bgColor = theme.palette.orange[100]
      bulletColor = theme.palette.success.main
      textColor = theme.palette.orange[500]
      break
    case 'completed':
      bgColor = theme.palette.grey[100]
      bulletColor = theme.palette.info.main
      textColor = theme.palette.grey[500]
      break
    case 'live':
      bgColor = theme.palette.tiptip[100]
      bulletColor = theme.palette.tiptip[500]
      textColor = theme.palette.tiptip[500]
      break
    case 'canceled':
      bgColor = theme.palette.text.tertiery
      bulletColor = theme.palette.text.tertiery
      textColor = theme.palette.text.tertiery
      break
    default:
      break
  }

  return (
    <SessionStatusContainer
      sx={{
        background: bgColor,
      }}
    >
      <StyledStatus>
        {status === 'live' && (
          <StyledBullet
            sx={{
              background: bulletColor,
            }}
          ></StyledBullet>
        )}
        <StyledTextStatus
          sx={{
            color: textColor,
          }}
        >
          {status.toUpperCase()}
        </StyledTextStatus>
      </StyledStatus>
      {type && type === 'premium' ? (
        <StyledType>
          {cardType == 'vertical' && (
            <StyleTypeText
              sx={{
                marginRight: '4px',
              }}
            >
              {type}
            </StyleTypeText>
          )}
          <StyledIconImage
            src={`${appConfig.assetBaseUrl}/mini-coin.png`}
          />
        </StyledType>
      ) : (
        <StyledType
          sx={{
            background: textColor,
          }}
        >
          <StyleTypeText>{type}</StyleTypeText>
        </StyledType>
      )}
    </SessionStatusContainer>
  )
}

export default SessionStatus
