import dateFormatter from 'helpers/formats/date'
import { IVideoCard } from './interface'
import {
  Bullet,
  LayoutCard,
  StyledCard,
  StyledCardContent,
  StyledCardMedia,
  StyledCoverImage,
  StyledImage,
  StyledSessionTitle,
} from './styled'
import SessionStatus from './SessionStatus'
import { Link } from '@mui/material'
import trackEvent from 'src/trackers'
import { useRouter } from 'next/router'
import CreatorCaption from 'components/commons/CreatorCaption'
import isEmpty from 'helpers/is-empty'
import handleErrorImage from 'helpers/error-image'
import { getLocalStorage } from 'helpers/local-storage'
import Box from 'components/commons/Box'
import CommonTypography from 'components/commons/Typography'

function VideoCard({
  id = '',
  title = '',
  video = '',
  name = '',
  date = '',
  url,
  status = '',
  type = '',
  cardType = 'vertical',
  pathName = '',
  groupName = '',
  creatorId = 'unknown',
  creatorUserId = 'unknown',
  creatorGroup = '',
  creatorIsCommunityLeader = '',
  categoryId = 'unknown',
  categoryName = 'unknown',
  className = '',
  isVerified = false,
  sectionId = '',
  sectionTitle = '',
  sectionContentType = '',
  sxContainer,
}: IVideoCard) {
  const { query } = useRouter()
  const trackerData = { pathname: pathName, query }

  const userId = getLocalStorage('userId')?.id
  const isSelf = userId === creatorUserId

  const trackData = () => {
    if (pathName !== '') {
      trackEvent.browse('select_session', trackerData, {
        state_sub_name: groupName,
        creator_id: creatorId,
        creator_user_id: creatorUserId,
        creator_category_id: categoryId,
        creator_category: categoryName,
        creator_group: creatorGroup,
        is_community_leader: creatorIsCommunityLeader,
        session_id: id,
        session_title: title,
        creator_name: name,
        selected_browse_and_search_menu: !isEmpty(sectionId)
          ? undefined
          : 'session_tab',
        section_id: !isEmpty(sectionId) ? sectionId : undefined,
        section_title: !isEmpty(sectionTitle)
          ? sectionTitle
          : undefined,
        section_content_type: !isEmpty(sectionContentType)
          ? sectionContentType
          : undefined,
        is_creator_own_profile: isSelf,
        session_type: type,
        session_estimate_start_time: date,
      })
    }
  }
  return (
    <LayoutCard
      className={className}
      onClick={trackData}
      sx={sxContainer}
    >
      <Link sx={{ textDecoration: 'none' }} href={url}>
        <StyledCard type={cardType}>
          <StyledImage type={cardType}>
            <StyledCoverImage
              alt="media"
              src={video}
              onError={({ currentTarget }) => {
                handleErrorImage({ currentTarget, type: 'session' })
              }}
            />
            <StyledCardMedia type={cardType} title={title} />
            {status !== '' && (
              <SessionStatus
                type={type}
                status={status}
                cardType={cardType}
              />
            )}
          </StyledImage>
          <StyledCardContent type={cardType}>
            <StyledSessionTitle>{title}</StyledSessionTitle>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box flex={1} sx={{ width: '100%' }}>
                <CreatorCaption
                  title={name}
                  isVerified={isVerified}
                  size="xs"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  minWidthChildren={'112px'}
                >
                  <CommonTypography
                    sx={(theme) => ({
                      ...theme.typography.smallRegular,
                      color: theme.palette.text.primary,
                    })}
                  >
                    <Bullet
                      sx={(theme) => ({
                        color: theme.palette.text.secondary,
                      })}
                    >
                      •
                    </Bullet>
                    {dateFormatter({
                      date,
                      format: 'DD MMM YYYY, HH:mm',
                    })}
                  </CommonTypography>
                </CreatorCaption>
              </Box>
            </Box>
          </StyledCardContent>
        </StyledCard>
      </Link>
    </LayoutCard>
  )
}

export default VideoCard
